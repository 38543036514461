<template>
  <div>
    <v-row>
      <v-col cols="12" md="2">
        <v-card>
          <v-list shaped>
            <v-subheader>Trip Request Sections</v-subheader>
            <v-list-item-group color="primary" v-model="activeIndex">
              <v-list-item v-for="(section, i) in formSections" :key="i" @click="viewSection(i)">
                <v-list-item-title>{{ section }}</v-list-item-title>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-card>
      </v-col>
      <v-col cols="12" md="10">
        <v-card>
          <v-form :readonly="!canEditSettings">
            <v-card-text>
              <v-container>
                <draggable v-model="activeSection" @sort="handleSort" :disabled="!canEditSettings">
                  <transition-group>
                    <v-row v-for="field in activeSection" :key="field.id">
                      <v-icon>mdi-drag-horizontal-variant</v-icon>
                      <v-col cols="auto">
                        <v-checkbox
                          v-model="field.required"
                          label="Required"
                          :disabled="field.delete || field.type == 3"
                        ></v-checkbox>
                      </v-col>
                      <v-col cols="2">
                        <v-select
                          v-model="field.type"
                          :items="typeOptions"
                          item-text="label"
                          item-value="value"
                          label="Type"
                          outlined
                          hide-details
                          :disabled="field.delete"
                        ></v-select>
                      </v-col>
                      <v-col cols="3">
                        <v-text-field
                          v-model="field.name"
                          label="Field Name / Question"
                          hide-details
                          :disabled="field.delete"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="2">
                        <v-select
                          v-model="field.size"
                          :items="sizeOptions"
                          item-text="label"
                          item-value="value"
                          label="Field Size"
                          outlined
                          hide-details
                          :disabled="field.delete"
                        ></v-select>
                      </v-col>
                      <v-col cols="auto">
                        <v-btn
                          class="mx-2"
                          fab
                          color="primary"
                          @click="openTripTypeSelector(field)"
                          v-if="canEditSettings"
                        >
                          <v-icon> mdi-cog </v-icon>
                        </v-btn>
                      </v-col>
                      <v-col cols="auto">
                        <v-btn class="mx-2" fab dark color="error" @click="deleteField(field)" v-if="canEditSettings">
                          <v-icon dark> {{ field.delete ? 'mdi-refresh' : 'mdi-delete' }} </v-icon>
                        </v-btn>
                      </v-col>
                    </v-row>
                  </transition-group>
                </draggable>
              </v-container>
            </v-card-text>
          </v-form>
          <v-card-actions>
            <v-col cols="auto">
              <v-btn class="mx-2" dark color="primary" @click="addCFF()" v-if="canEditSettings">
                <v-icon>mdi-plus</v-icon>Add Field
              </v-btn>
            </v-col>
            <v-col cols="auto">
              <v-btn
                class="mx-2"
                dark
                color="success"
                @click="save()"
                v-show="activeSection && activeSection.length"
                v-if="canEditSettings"
              >
                <v-icon>mdi-content-save</v-icon>Save
              </v-btn>
            </v-col>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>

    <v-dialog v-model="showSettingsDialog" width="800">
      <v-card>
        <v-card-title>Custom Field Settings</v-card-title>
        <v-card-text>
          <v-subheader> Display this field for the following Trip Types: </v-subheader>
          <v-row dense>
            <v-col cols="12" md="3" class="checkbox">
              <v-checkbox v-model="field.selectAllTypes" label="All" @change="handleAllTypes"></v-checkbox>
            </v-col>
            <v-col cols="12" md="3" v-for="(type, i) in tripTypes" :key="i" class="checkbox">
              <v-checkbox
                :value="type.id"
                v-model="field.tripTypeIds"
                :label="type.name"
                multiple
                @click="handleTripTypeSelected"
              ></v-checkbox>
            </v-col>
          </v-row>
          <v-subheader>Overnight or Out-of-State specific?</v-subheader>
          <v-row dense>
            <v-col cols="12" md="6">
              <v-radio-group v-model="field.oos" row mandatory>
                <v-radio label="Yes" :value="true"></v-radio>
                <v-radio label="No" :value="false"></v-radio>
              </v-radio-group>
            </v-col>
          </v-row>
          <v-subheader v-if="field.type == 4"> Add list items (type in an option then press ENTER): </v-subheader>
          <v-row dense v-if="field.type == 4">
            <v-col cols="12">
              <v-combobox v-model="field.items" :items="[]" multiple chips></v-combobox>
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="showSettingsDialog = false"> Confirm </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { GET_CONFIG, SET_CONFIG } from '@/store/modules/Config/actions';
import draggable from 'vuedraggable';
import { CustomFormFieldsSetup } from '@/components/shared';
import { randomString } from '@/util';
import promptBeforeLeave from '@/mixins/promptBeforeLeave';
import { cloneDeep } from 'lodash';

export default {
  name: 'CFF',
  inject: ['eventHub'],
  components: { draggable },
  data() {
    return {
      randomString,
      cff: {},
      field: {},
      formSections: [
        'General',
        'Trip Leave / Return',
        'Destination(s)',
        'Attendees',
        'Transportation',
        'Funding',
        'Additional Info',
        'Supporting Documents',
      ],
      activeSection: [],
      activeIndex: 0,
      typeOptions: [
        { label: 'Text', value: 0 },
        { label: 'Number', value: 1 },
        { label: 'Date', value: 2 },
        { label: 'Checkbox', value: 3 },
        { label: 'List', value: 4 },
      ],
      sizeOptions: [
        { label: '1/4', value: '3' },
        { label: '1/3', value: '4' },
        { label: '1/2', value: '6' },
        { label: '2/3', value: '8' },
        { label: '3/4', value: '9' },
        { label: 'Full Width', value: '12' },
      ],
      showSettingsDialog: false,
      selectAllTypes: false,
      toModify: {
        name: 'tripRequest',
        fields: [],
      },
    };
  },
  computed: {
    ...mapGetters('user', ['me', 'canEditSettings']),
    ...mapGetters('tripType', ['tripTypes']),
  },
  mounted() {
    this.fetchItems();
  },
  methods: {
    ...mapActions('config', [GET_CONFIG, SET_CONFIG]),
    async fetchItems() {
      const config = await this.getConfig('customFormFields');
      if (config) this.cff = config;
      else for (let i = 0; i < 8; i++) this.cff[i] = [];
      this.activeSection = this.cff[this.activeIndex];
      this.baseFormValue = cloneDeep(this.activeSection);
    },
    async viewSection(section) {
      const prev = this.activeIndex;
      const yes = await this.performPromptBeforeLeave();
      if (!yes) {
        this.activeIndex = prev;
        return;
      }

      this.activeIndex = section;
      this.activeSection = this.cff[section];
      this.baseFormValue = cloneDeep(this.activeSection);
    },
    addCFF() {
      this.activeSection.push({
        id: this.randomString(12),
        type: 0,
        name: '',
        size: '3',
        required: false,
        tripTypeIds: this.tripTypes.map((e) => e.id),
        oos: false,
      });
    },
    async handleSort({ oldIndex, newIndex }) {
      if (oldIndex != newIndex) {
        this.cff[this.activeIndex] = this.activeSection;
      }
    },
    async save() {
      try {
        const keys = Object.keys(this.cff);
        for (let key of keys) this.cff[key] = this.cff[key].filter((e) => !e.delete);
        const r = await this.setConfig({ name: 'customFormFields', value: this.cff });
        if (r && r.done) {
          this.$myalert.success('Custom Form Fields saved');
          await this.fetchItems();
        }
      } catch (error) {
        this.$myalert.error(error.message);
      }
    },
    deleteField(field) {
      field.delete = !field.delete;
      this.activeSection.push({});
      this.activeSection.pop();
    },
    openTripTypeSelector(field) {
      this.field = field;
      this.field.selectAllTypes = this.field.tripTypeIds.length == this.tripTypes.length;
      this.showSettingsDialog = true;
    },
    handleAllTypes() {
      if (this.field.tripTypeIds.length == this.tripTypes.length) this.field.tripTypeIds = [];
      else this.field.tripTypeIds = this.tripTypes.map((e) => e.id);
      this.handleTripTypeSelected();
    },
    handleTripTypeSelected() {
      if (this.field.tripTypeIds.length == this.tripTypes.length) this.field.selectAllTypes = true;
      else this.field.selectAllTypes = false;
    },
  },
  watch: {
    activeSection: {
      handler(value) {
        this.latestFormValue = cloneDeep(value);
      },
      deep: true,
    },
  },
  mixins: [promptBeforeLeave],
};
</script>

<style lang="scss">
.table-action {
  margin-left: 10px;
}
.checkbox {
  display: flex;
  align-items: center !important;
}
</style>
